import React from 'react';

import Globe from '../../icons/index-ibi/globe.svg';
import Peace from '../../icons/index-ibi/peace.svg';
import Connect from '../../icons/index-ibi/connect.svg';

const strings = {
  reachReal: 'Reach Real People',
  everyBrand: 'Every brand has enthusiasts just waiting to be discovered. We connect you with a broad, diverse audience across the globe.',
  boostYour: 'Boost Your Skill Set',
  ourTeam:
    'Our team is composed of committed experts and industry icons eager to share their experience, knowledge, and trusted tools with you.',
  joinAVib: 'Join a Vibrant Community',
  enjoyExclusive:
    'Enjoy exclusive access to resources, connections, events, and support to further develop yourself professionally.',
};

export const callToActionList = [
  {
    title: strings.reachReal,
    description: strings.everyBrand,
    icon: <Globe />,
  },
  {
    title: strings.boostYour,
    description: strings.ourTeam,
    icon: <Peace />,
  },
  {
    title: strings.joinAVib,
    description: strings.enjoyExclusive,
    icon: <Connect />,
  },
];
